import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import App from "@/pages/App";
import AuthPage from "@/pages/Auth";
import ErrorPage from "@/pages/ErrorPage";
import Auth0ProviderWithRedirect from "@/providers/Auth0Provider";
import { ProtectedRoute } from "@/components/Security";

const routesElements = createRoutesFromElements(
  <Route element={<Auth0ProviderWithRedirect />}>
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route // MARK: FREELANCERS ROUTES
        element={
          <ProtectedRoute
            requiredRoles= {["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL"]}
            redirectTo="/"
            />
          }
        >
        <Route
          path="freelancers"
          lazy={async () => {
            const FreelancersPage = (await import("@/pages/Freelancers")).default;
            return {
              Component: FreelancersPage,
            };
          }}
          errorElement={<ErrorPage />}
        />
        <Route
          path="freelancers/:id"
          lazy={async () => {
            const FreelancerDetailPage = (await import("@/pages/FreelancerDetail")).default;
            return {
              Component: FreelancerDetailPage,
            };
          }}
          errorElement={<ErrorPage />}
        />
      </Route>
      <Route // MARK: USERS ROUTES
        element={
          <ProtectedRoute
            requiredRoles= {["BACKOFFICE", "BACKOFFICE_OPS", "EXTERNAL_BACKOFFICE"]}
            redirectTo="/"
            />
          }
        >
          <Route
            path="users"
            lazy={async () => {
              const UsersPage = (await import("@/pages/Users")).default;
              return {
                Component: UsersPage,
              };
            }}
            errorElement={<ErrorPage />}
          />
          <Route
            path="users/:id"
            lazy={async () => {
              const UserDetailPage = (await import("@/pages/UserDetail")).default;
              return {
                Component: UserDetailPage,
              };
            }}
            errorElement={<ErrorPage />}
          />
        </Route>
        <Route // MARK: COMPANIES ROUTES
          element={
            <ProtectedRoute
              requiredRoles= {["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL"]}
              redirectTo="/"
              />
          }
          >
          <Route
            path="companies"
            lazy={async () => {
              const CompaniesPage = (await import("@/pages/Companies")).default;
              return {
                Component: CompaniesPage,
              };
            }}
            errorElement={<ErrorPage />}
          />
          <Route
            path="companies/:id"
            lazy={async () => {
              const CompanyDetailPage = (await import("@/pages/CompanyDetail")).default;
              return {
                Component: CompanyDetailPage,
              };
            }}
            errorElement={<ErrorPage />}
          />
        </Route>
        <Route // MARK: EVENTS ROUTES
          element={
            <ProtectedRoute
              requiredRoles= {["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL", "EXTERNAL_BACKOFFICE", "EXTERNAL_BACKOFFICE_OPS"]}
              redirectTo="/"
              />
          }
          >
          <Route
            path="events"
            lazy={async () => {
              const { EventsPage } = await import("@/pages/Events");
              return {
                Component: EventsPage,
              };
            }}
          />
          <Route
            path="event/:id"
            lazy={async () => {
              const { EventDetailPage } = await import("@/pages/Events/EventDetail");
              return {
                Component: EventDetailPage,
              };
            }}
          />
          <Route
              path="event/overtime-requests"
              lazy={async () => {
                const { OvertimeRequestsPage } = await import("@/pages/Events");
                return {
                  Component: OvertimeRequestsPage,
                };
              }}
            />
        </Route>
        <Route // MARK: DASHBOARD ROUTES
          element={
            <ProtectedRoute
              requiredRoles= {["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL", "EXTERNAL_BACKOFFICE", "EXTERNAL_BACKOFFICE_OPS"]}
              redirectTo="/"
              />
          }
          >
          <Route
            path="operations"
            lazy={async () => {
              const { OperationRootPage } = await import("@/pages/Operations");
              return {
                Component: OperationRootPage,
              };
            }}
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route
              path="dashboard"
              lazy={async () => {
                const { OperationDashboardPage } = await import("@/pages/Operations");
                return {
                  Component: OperationDashboardPage,
                };
              }}
            />
          </Route>
        </Route>
        <Route // MARK: FINANCIAL ROUTES
          element={
            <ProtectedRoute
              requiredRoles= {["BACKOFFICE", "BACKOFFICE_FINANCIAL"]}
              redirectTo="/"
              />
          }
          >
            <Route
              path="financial"
              lazy={async () => {
                const { FinancialRootPage } = await import("@/pages/Financial");
                return {
                  Component: FinancialRootPage,
                };
              }}
            >
              <Route path="" element={<Navigate to="payment-orders" replace />} />
              <Route
                path="payment-orders"
                lazy={async () => {
                  const { PaymentOrdersPage } = await import("@/pages/Financial");
                  return {
                    Component: PaymentOrdersPage,
                  };
                }}
              />
              <Route
                path="payment-orders/:id"
                lazy={async () => {
                  const { PaymentOrderDetailPage } = await import("@/pages/Financial");
                  return {
                    Component: PaymentOrderDetailPage,
                  };
                }}
              />
              <Route
                path="overtime-requests"
                lazy={async () => {
                  const { OvertimeRequestsPage } = await import("@/pages/Financial");
                  return {
                    Component: OvertimeRequestsPage,
                  };
                }}
              />
            </Route>
          </Route>
        <Route // MARK: SETTINGS ROUTES
          element={
            <ProtectedRoute
              requiredRoles= {["BACKOFFICE", "BACKOFFICE_OPS"]}
              redirectTo="/"
              />
          }
          >
          <Route
            path="settings"
            lazy={async () => {
              const { SettingsRootPage } = await import("@/pages/Settings");
              return {
                Component: SettingsRootPage,
              };
            }}
          >
          <Route path="" element={<Navigate to="manage-questions" replace />} />
          <Route
            path="manage-questions"
            lazy={async () => {
              const { QuestionsPage } = await import("@/pages/Settings");
              return {
                Component: QuestionsPage,
              };
            }}
          />
          {/* MARK: you can use the bellow approach, or the above approach to create a new route in the settings path. */}
          {/* <Route path="test" Component={() => <h1>second page</h1>} /> */}
      </Route>
      </Route>
    </Route>
    <Route path="login" element={<AuthPage />} errorElement={<ErrorPage />} />
  </Route>,
);

/**
 * The router for the application. This is the top-level router.
 */
export const router = createBrowserRouter(routesElements);
